import { Input, TextInput } from "@narmi/design_system";
import Cleave from "cleave.js/react";
import React, { useImperativeHandle, useRef } from "react";

// server value is YYYY-MM-DD
// client display value is MM/DD/YYYY
export const getDisplayValue = (storedValue: string) => {
  if (!storedValue) return "";
  const dateAsArr = storedValue.split("-");
  const year = dateAsArr[0] || "";
  const cutOffYear = year.length > 4;
  const yearDisplay = cutOffYear ? `${year.substring(0, 4)}` : year;

  const month = dateAsArr[1] || "";
  const showMonthSlash = month.length > 1;
  const monthDisplay = showMonthSlash ? `${month}/` : month;

  const day = dateAsArr[2] || "";
  const showDaySlash = day.length > 1;
  const dayDisplay = showDaySlash ? `${day}/` : day;

  return `${monthDisplay}${dayDisplay}${yearDisplay}`;
};

export const getStoredValue = (displayValue: string) => {
  if (!displayValue) return "";
  const dateAsArr = displayValue.split("/");
  const numSegments =
    dateAsArr[dateAsArr.length - 1] === "" ? dateAsArr.length - 1 : dateAsArr.length;
  let shouldZeroPrefix = false;
  if (numSegments === 1) {
    if (displayValue.slice(-1) === "/" && dateAsArr[0].length < 2) {
      shouldZeroPrefix = true;
    }
    return `-${shouldZeroPrefix ? "0" : ""}${dateAsArr[0]}-`;
  } else if (numSegments === 2) {
    if (displayValue.slice(-1) === "/" && dateAsArr[1].length < 2) {
      shouldZeroPrefix = true;
    }
    return `-${dateAsArr[0]}-${shouldZeroPrefix ? "0" : ""}${dateAsArr[1]}`;
  } else {
    return `${dateAsArr[2]}-${dateAsArr[0]}-${dateAsArr[1]}`;
  }
};

export const MaskedSimpleDateInput = ({
  isInitiallyMasked,
  label,
  onChange,
  onBlur,
  value,
  error,
}: {
  isInitiallyMasked: boolean;
  label: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  error: string;
}) => {
  const [showMask, setShowMask] = React.useState(isInitiallyMasked);
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isInitiallyMasked && !showMask) {
      ref.current?.focus();
    }
  }, [isInitiallyMasked, showMask]);

  if (showMask) {
    return (
      <TextInput value="**/**/****" label={label} readOnly onFocus={() => setShowMask(false)} />
    );
  }
  return (
    <SimpleDateInput
      ref={ref}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={error}
    />
  );
};

/**
 *
 * NDS TextInput configured for entering a date.
 *
 * `SimpleDateInput` is intended to be used as a controlled component.
 *
 * Only numeric characters are permitted.
 *
 * Use this instead of the NDS `DateInput` when the date picker is not desired.
 *
 */
const SimpleDateInput = React.forwardRef<
  { focus: () => void },
  {
    label: string;
    value: string;
    onChange: (value: string) => void;
    error?: string;
    name?: string;
    id?: string;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
  }
>(
  (
    {
      label,
      value,
      onChange,
      error = null,
      name = "date_input",
      id = "date_input",
      disabled,
      ...otherTextInputProps
    },
    parentRef
  ) => {
    const inputEl: React.MutableRefObject<HTMLInputElement | null> =
      useRef<HTMLInputElement | null>(null);

    useImperativeHandle(parentRef, () => {
      return {
        focus() {
          inputEl.current?.focus();
        },
      };
    }, []);

    return (
      <Input name={name} id={id} label={label} error={error} disabled={disabled}>
        <Cleave
          inputMode="numeric"
          // lmao wtf https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-get-ref-of-the-input-field
          htmlRef={(ref) => (inputEl.current = ref)}
          value={getDisplayValue(value)}
          options={{
            date: true,
            datePattern: ["m", "d", "Y"],
          }}
          onChange={(event) => {
            // only propagate the event if it's a valid date
            if (event.target.value.length == 10) {
              onChange(getStoredValue(event.target.value));
            }
          }}
          name={name}
          id={id}
          placeholder={label}
          aria-label={label}
          required
          type="tel"
          key="nds-text"
          {...otherTextInputProps}
        />
      </Input>
    );
  }
);

export default SimpleDateInput;
