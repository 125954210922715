/* eslint-disable camelcase */
import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressButtons } from "cerulean";
import { entities, modules, cents } from "byzantine";
import { DateTime } from "luxon";
import { Row, ResponsiveFlex } from "@narmi/design_system";
import { useLocalization } from "@fluent/react";
import filters from "byzantine/src/filters";
import Account from "byzantine/src/Account";
import { Features } from "byzantine/src/Feature";
import { v4 as uuidv4 } from "uuid";
import HeaderWithSteps from "../../HeaderWithSteps";
import AccountContext from "../../contexts/AccountContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import styles from "./ReviewScreen.module.scss";
import {
  ACH_PAYMENT_ROUTE,
  ACH_PAYMENT_DETAIL_ROUTE,
  ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE,
} from "./ACHPaymentRoutes";
import { getTotalSteps } from "./RecipientScreen";

const ReviewScreen = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const features = useUserFeatures() as Features;
  const { accounts } = useContext(AccountContext);
  const { createACHPayment, loading } =
    modules.achPayments.useCreateACHPayment();
  const form = modules.achPayments.ACHPaymentForm.useForm();
  const selectedRecipient = entities.recipients.useSelectedRecipient();
  const achCompany = entities.achCompanies.useACHCompany();
  const achScheduledEnabled = features.ach_payments_scheduled;

  if (!selectedRecipient) return null;

  const {
    values: { amount, fromAccount, secCode },
  } = form;
  const { name, ach_destination, nickname } = selectedRecipient;

  const {
    account_number: accountNumber,
    routing_number: routingNumber,
    institution_name: institutionName,
  } = ach_destination!;

  const senderAccount = accounts.find(
    (account: Account) => account.id === fromAccount,
  );

  const handleGoBack = useCallback(() => {
    const route = achScheduledEnabled
      ? ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE
      : ACH_PAYMENT_DETAIL_ROUTE;
    navigate(`/${ACH_PAYMENT_ROUTE}/${route}`);
  }, [navigate]);

  const idempotencyKey = useMemo(() => uuidv4() as UUID, []);

  const handleClickSubmit = () => {
    const onSuccess = () => {
      navigate("/", { replace: true });
    };
    createACHPayment(idempotencyKey, { onSuccess, onError: handleGoBack });
  };

  const renderRow = (row: string[], index: number) => {
    const [heading, content] = row;
    return (
      <Row key={`row-${heading}__${index}`}>
        <Row.Item>
          <p className="fontColor--secondary">{heading}</p>
        </Row.Item>
        <Row.Item shrink>
          <p>{content}</p>
        </Row.Item>
      </Row>
    );
  };

  const senderRows = [
    [l10n.getString("ach-payment-review-from"), senderAccount.name],
    [
      l10n.getString("ach-payment-review-company"),
      `${achCompany?.company_name} / ${achCompany?.company_id}`,
    ],
    [
      l10n.getString("ach-payment-review-date"),
      DateTime.now().toFormat("MM/dd/yyyy"),
    ],
    [l10n.getString("ach-payment-transaction-title"), `${secCode} payment`],
  ];

  const recipientRows = [
    [l10n.getString("ach-payment-recipient-title"), name],
    [l10n.getString("ach-payment-review-bank"), institutionName],
    [l10n.getString("ach-payment-review-account"), accountNumber],
    [l10n.getString("ach-payment-review-routing"), routingNumber],
  ];

  return (
    <ResponsiveFlex gapSize="xl">
      <HeaderWithSteps
        headerText={l10n.getString("ach-payment-review-title")}
        step={getTotalSteps()}
        totalSteps={getTotalSteps()}
      />
      <div className={styles.reviewCardContainer}>
        <div className={styles.reviewCardHeader}>
          <p>
            {l10n.getString("ach-payment-review-sub-title")} {name}{" "}
            {nickname ? (
              <span className="fontColor--secondary fontSize--xs">{`(${nickname})`}</span>
            ) : null}
          </p>
          <h1>{`${filters.currency(cents(amount))}`}</h1>
        </div>
        <div className={styles.reviewCardSection}>
          <ResponsiveFlex gapSize="m">
            {senderRows.map((row, index) => renderRow(row, index))}
          </ResponsiveFlex>
        </div>
        <div className={styles.reviewCardSection}>
          <ResponsiveFlex gapSize="m">
            {recipientRows.map((row, index) => renderRow(row, index))}
          </ResponsiveFlex>
        </div>
      </div>
      <ProgressButtons
        backLabel={l10n.getString("button-back")}
        nextLabel={l10n.getString("ach-payment-review-action")}
        onBack={handleGoBack}
        onNext={handleClickSubmit}
        disabled={loading}
        disableNext={loading}
        isNextLoading={loading}
      />
    </ResponsiveFlex>
  );
};

export default ReviewScreen;
