import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { selectBAccount, selectBAccounts } from "./selectors";

import type { RootState } from "../store";
import type Account from "../../../../Account";

export const useBAccount = (id: API.AccountId): Account | undefined => {
  return useSelector((state: RootState) => selectBAccount(state, id), isEqual);
};

export const useAccounts = () => {
  return useSelector(selectBAccounts);
};
