import * as Yup from "yup";

import { createForm } from "../../forms";
import { cents } from "../../../utils";

export type ACHPaymentFormType = {
  fromAccount: string;
  amount: Cents;
  secCode: string;
  memo?: string;
  entryDesc?: string;
  startDate: string;
};

export const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  secCode: Yup.string().required("Required"),
});

export const paymentDetailsValidationSchema = validationSchema
  .pick(["fromAccount", "amount"])
  .concat(
    Yup.object().shape({
      startDate: Yup.string().required("Required"),
    }),
  );

export const additionalDetailsValidationSchema =
  paymentDetailsValidationSchema.concat(
    Yup.object().shape({
      secCode: Yup.string().required("Required"),
      memo: Yup.string().max(128),
      entryDesc: Yup.string().max(10),
    }),
  );

const initialValues: ACHPaymentFormType = {
  fromAccount: "",
  amount: cents(0),
  secCode: "",
  memo: "",
  entryDesc: "",
  startDate: new Date().toISOString(),
};

export const transformACHPaymentFormFieldsToApiFields = ({
  amount,
  fromAccount: institution_account,
  achCompany: ach_company,
  secCode: std_ent_cls_code,
  entryDesc: entry_desc,
  recipient,
}: ACHPaymentFormType & {
  recipient: API.RecipientId;
  entryDesc: API.CreateACHPayment.Request["entry_desc"];
  achCompany: API.CreateACHPayment.Request["ach_company"];
}) => {
  return {
    amount,
    institution_account,
    ach_company,
    std_ent_cls_code,
    entry_desc,
    recipient,
  } as API.CreateACHPayment.Request;
};

export const ACHPaymentForm = createForm({
  initialValues,
  validationSchema,
});
