import React, { useState, useEffect, useLayoutEffect } from "react";
import ISO6391 from "iso-639-1";
import { ReactLocalization } from "@fluent/react";
import { LANG_CHANGE, LANG_ERROR } from "../browserCustomEvents.js";
import { getAllBundles, getReactLocalization } from "./util.js";
import { DEBUG_STYLES } from "./debugStyles.js";
import { getSelectedLocale } from "byzantine/src/l10n/InstanceHelpers";

/**
 * We only fetch FTLs on the client, so on initial render
 * OR server render, make the parser in the ReactLocalization
 * return a single text node for `Localized` contents
 *
 * @param {String} htmlFragment
 * @returns {Array} NodeList with single text node
 */
export const ssrMarkupParser = (htmlFragment) => [
  {
    nodeName: "#text",
    textContent: htmlFragment.toUpperCase(),
  },
];

/**
 * Convert a locale code to a native name
 * @param {String} localeCode
 */
const toNativeName = (localeCode) => {
  const nativeName = ISO6391.getNativeName(localeCode);
  if (nativeName.length < 1) {
    return "DEBUG MODE";
  }
  return nativeName;
};

// @see <https://github.com/narmi/banking/blob/70a1167f8cc6f83118fd533bc2b9f39568b78512/indigo/settings/utils.py#L10>
const isUat = (institutionInternalName) => {
  const normalizedName = `${institutionInternalName}`.toLowerCase();
  return normalizedName.endsWith("uat") || normalizedName.endsWith("local");
};

/**
 * Allow deep linking to any page with a language already selected
 * (e.g. narmiurl.com?lang=es)
 */
const getLangParam = () => {
  let langFromParams;
  if (typeof window !== "undefined") {
    const langValue = new URL(document.location).searchParams.get("lang");
    if (langValue) {
      langFromParams = langValue;
    }
  }
  return langFromParams;
};

/**
 * Hook that returns a `ReactLocalization` that can be passed as the `l10n`
 * prop to a Fluent `LocalizationProvider`.
 *
 * This hook also returns the necessary information and setter for building
 * a language selection UI within the consuming application.
 *
 * When `setSelectedLocale` is called with a new locale code, the `l10n` value
 * returned from this hook will update.
 *
 * @usage
 * ```
 * const {
 *    l10n, // pass this to your `LocalizationProvider`
 *    isLoading,
 *    isMultiLingual,
 *    localeCodes,
 *    localeNames,
 *    selectedLocale,
 *    setSelectedLocale,
 * } = useFluentLocalization({
 *    internalName: "cathay_UAT",
 *    productCode: "olb",
 *    apiUrl: `${API_URL_ENV_VAR}/v1/`,
 *    isMfe: false, // microfrontend flag (azul)
 * })
 * ```
 */
const useFluentLocalization = ({
  internalName,
  s3ImagesBucket,
  productCode,
  apiUrl,
  isMfe = false,
}) => {
  const [isLoading, setIsLoading] = useState(true); // until success or error
  const [l10n, setL10n] = useState(new ReactLocalization([], ssrMarkupParser));
  const [bundlesMap, setBundlesMap] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(getLangParam());
  const [localeCodes, setLocaleCodes] = useState(["en"]);
  const [localeNames, setLocaleNames] = useState(["English"]);
  const showDebugOption = isUat(internalName);

  // This effect ensures `bundlesMap` is only populated on client side render.
  //
  // The effect is run again if `selectedLocale` changes, updating the `l10n`
  // property returned by this hook.
  useEffect(() => {
    async function populateBundlesMap() {
      const bundleData = await getAllBundles(
        internalName,
        s3ImagesBucket,
        productCode,
        apiUrl,
        showDebugOption
      );
      if (typeof bundleData.error !== "undefined") {
        if (isMfe) {
          window.dispatchEvent(new CustomEvent(LANG_ERROR));
        }
        setIsLoading(false);
        console.error(bundleData.error);
      } else {
        setBundlesMap(bundleData.bundles);
        setIsLoading(false);
      }
    }

    try {
      const storedLocale = window.localStorage.getItem("selectedLocale");
      // Persist user language selection across sessions and pages
      if (!selectedLocale && storedLocale) {
        setSelectedLocale(storedLocale);
      } else {
        // if selectedLocale changes via user action, add it to localStorage
        window.localStorage.setItem("selectedLocale", selectedLocale);
      }
    } catch {
      // an error occurred accessing local storage
    }

    // handle microfrontend case if applicable; prevent multiple
    // react roots from fetching the same FTLs
    const isAlreadyFetching = isMfe ? window.isFetchingFtls : false;

    // consumers of this hook typically need to fetch to get `internalName`,
    // so we must wait until we have a value before populating bundles
    if (!bundlesMap && !isAlreadyFetching && internalName) {
      if (isMfe) {
        window.isFetchingFtls = true;
      }
      populateBundlesMap();
    }

    if (bundlesMap) {
      const isUserSelectableFilter = (key) =>
        !key.includes("-settings") && !key.includes("-default");
      const reactLocalization = getReactLocalization(selectedLocale, bundlesMap);
      const codes = Object.keys(bundlesMap).filter(isUserSelectableFilter);
      const names = Object.keys(bundlesMap).filter(isUserSelectableFilter).map(toNativeName);
      setL10n(reactLocalization);
      setLocaleCodes(codes);
      setLocaleNames(names);
    }
  }, [internalName, selectedLocale, bundlesMap]);

  useEffect(() => {
    if (!isMfe) {
      window.document.documentElement.lang = selectedLocale || "en";
    }
  }, [selectedLocale]);

  // Microfrontend CustomEvent pub/sub
  // Allows a `ReactLocalization` to be shared between multiple React roots
  useEffect(() => {
    if (isMfe) {
      window.addEventListener(LANG_CHANGE, ({ detail: l10n }) => {
        if (!l10n.areBundlesEmpty()) {
          setL10n(l10n);
          setIsLoading(false);
          window.document.documentElement.lang = getSelectedLocale(l10n);
        }
      });
      window.addEventListener(LANG_ERROR, () => {
        setIsLoading(false);
      });
    }
    return () => {
      if (isMfe) {
        window.removeEventListener(LANG_CHANGE);
      }
    };
  }, [isMfe]);

  // Debug mode styles
  useLayoutEffect(() => {
    let style;
    if (selectedLocale === "debug") {
      style = document.createElement("style");
      style.textContent = DEBUG_STYLES;
      document.head.appendChild(style);
    }
    return () => (style ? style.remove() : null);
  }, [selectedLocale]);

  const isMultilingual = localeCodes.length > 1;

  return {
    l10n,
    isLoading,
    isMultilingual,
    localeCodes,
    localeNames,
    selectedLocale,
    setSelectedLocale,
  };
};

export default useFluentLocalization;
