import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import M2M from "byzantine/src/M2M";
import MemberFriend from "byzantine/src/MemberFriend";
import { Button, TextInput, Select } from "@narmi/design_system";
import { ContextForm, NotificationContext } from "cerulean";
import { useUserFeatures } from "../../components/contexts/UserFeaturesContext";
import AmountTextInput from "../form/AmountTextInput";
import NewMemberModal from "./NewMemberModal";
import AccountContext from "../contexts/AccountContext";
import AccountSelector from "../AccountSelector";
import ActionHeader from "./ActionHeader";
import utils from "../../utils";

const MemberSelector = ({
  memberFriends,
  accounts,
  label,
  onChange,
  updateMemberFriends,
  value,
  error,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const features = useUserFeatures();
  const { l10n } = useLocalization();

  return (
    <>
      <Select label={label} error={error} onChange={onChange} value={value}>
        {memberFriends
          .filter((m) => m.description)
          .map(({ id, description }, i) => (
            <Select.Item
              key={`${id}-${i}`}
              value={id}
              searchValue={description}
            >
              {description}
            </Select.Item>
          ))}
        {!!features.olb_allow_add_member && (
          <Select.Action onSelect={() => setModalOpen(true)}>
            <span className="fontColor--pine fontWeight--bold">
              <span className="narmi-icon-plus padding--right--xs" />
              {l10n.getString("add-new-member", null, "Add a new member")}
            </span>
          </Select.Action>
        )}
      </Select>
      <NewMemberModal
        accounts={accounts}
        open={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        newMemberAdded={updateMemberFriends}
      />
    </>
  );
};

MemberSelector.defaultProps = {
  closeDropdown: () => null,
};

MemberSelector.propTypes = {
  accounts: PropTypes.array,
  updateMemberFriends: PropTypes.func,
  memberFriends: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
};

const M2MTransferForm = ({
  memberFriends,
  onChange,
  onSubmit,
  cancel,
  setMemberFriends,
}) => {
  const { accounts } = useContext(AccountContext);
  const notification = useContext(NotificationContext);
  const { l10n } = useLocalization();

  const validateTransferAmount = (value, allFields) => {
    const { from_account_id } = allFields;
    const transferAmountAsFloat = utils.parseValueAsFloat(value);
    if (transferAmountAsFloat <= 0) {
      return "Please enter a valid transfer amount.";
    }
    const m2m = new M2M({
      amount: transferAmountAsFloat,
      from_account_id,
      accounts,
    });
    // We currently only support one-time immediate M2M transfers
    // For M2M, source account is always internal, so we only need to validate against available balance
    return m2m.validateTransferAmountDoesNotExceedAvailableBalance();
  };

  return (
    <div className="form-card-layout">
      <div className="card">
        <ActionHeader
          title={l10n.getString("heading-send-money", null, "Send money")}
          icon="m2m"
        />
        <ContextForm.Field required>
          <AccountSelector
            field="from_account_id"
            label={l10n.getString("label-from", null, "From")}
            accounts={accounts.filter(
              (a) => a.isInternal() && a.isValidTransferSource(),
            )}
            showAddExternalAccountLink={false}
          />
        </ContextForm.Field>
        <ContextForm.Field required>
          <MemberSelector
            field="to_member_id"
            label={l10n.getString("label-to", null, "To")}
            accounts={accounts.filter(
              (a) => a.isInternal() && a.isValidTransferSource(),
            )}
            memberFriends={memberFriends}
            updateMemberFriends={(new_member_id) => {
              MemberFriend.getMemberFriends().then((mfs) => {
                setMemberFriends(mfs);
                onChange({ to_member_id: new_member_id });
                notification.sendNotification({
                  type: "success",
                  text: l10n.getString("member-added", null, "Member added."),
                });
              });
            }}
          />
        </ContextForm.Field>
        <ContextForm.Field required validate={validateTransferAmount}>
          <AmountTextInput
            field="amount"
            label={l10n.getString("label-amount", null, "Amount")}
          />
        </ContextForm.Field>
        <ContextForm.Field>
          <TextInput
            field="memo"
            label={l10n.getString(
              "label-memo-optional",
              null,
              "Memo (optional)",
            )}
            maxLength={128}
          />
        </ContextForm.Field>
        <ContextForm.ActionBar vertical>
          <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
            <Button
              label={l10n.getString("button-continue", null, "Continue")}
            />
          </ContextForm.Action>
          <div className="transfer-cancel-button">
            <Button
              onClick={() => cancel()}
              kind="negative"
              type="button"
              label={l10n.getString("button-cancel", null, "Cancel")}
            />
          </div>
        </ContextForm.ActionBar>
      </div>
    </div>
  );
};

M2MTransferForm.propTypes = {
  accounts: PropTypes.array,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  setMemberFriends: PropTypes.func,
  memberFriends: PropTypes.array,
};

export default M2MTransferForm;
