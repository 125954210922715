import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProgressButtons,
  Tooltip,
  ResponsiveFlex,
  Select,
  TextInput,
  Toggle,
  RadioButtons,
  ContentCard,
  Row,
  DateInput,
} from "cerulean";
import { modules, entities } from "byzantine";
import { Features } from "byzantine/src/Feature";
import type Account from "byzantine/src/Account";
import { useLocalization } from "@fluent/react";
import { Button, TruncatedAccount } from "@narmi/design_system";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import * as formAdapters from "../../../src/adapters";
import AccountContext from "../../contexts/AccountContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import AccountSelector from "../../AccountSelector";
import PreviewCard from "../../PreviewCard/PreviewCard";
import FrequencySelector from "../FrequencySelector";
import {
  ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE,
  ACH_PAYMENT_REVIEW_ROUTE,
  ACH_PAYMENT_ROUTE,
  LimitsForDisplayType,
} from "./ACHPaymentRoutes";
import SECModal from "./SECDialog";
import LimitsAndDisclosuresDialog from "./LimitsAndDisclosuresDialog";
import RecipientDrawer, {
  DRAWER_TYPES,
} from "./RecipientDrawer/RecipientDrawer";
import { getTotalSteps } from "./RecipientScreen";

export const TransactionTypeSection = ({
  codes,
}: {
  codes: API.ACHPayment.SECCode[];
}) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as Features;
  const [showSECDialog, setShowSECDialog] = useState(false);
  const form = modules.achPayments.ACHPaymentForm.useForm();

  const toggleSECDialog = () => {
    setShowSECDialog(!showSECDialog);
  };

  const achScheduledEnabled = features.ach_payments_scheduled;

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {achScheduledEnabled
            ? l10n.getString("add-recipient-drawer-additional-details-title")
            : l10n.getString("ach-payment-transaction-title")}
        </h4>
        <Select
          label={
            achScheduledEnabled
              ? l10n.getString("ach-payment-transaction-title")
              : l10n.getString("ach-payment-sec-code")
          }
          {...formAdapters.select(form, "secCode")}
        >
          {codes.map((code, index) => (
            <Select.Item key={`account_type_${index}`} value={code}>
              {code}
            </Select.Item>
          ))}
        </Select>
        <Button
          label={l10n.getString("ach-payment-view-transaction-types-cta")}
          kind="plain"
          size="s"
          onClick={toggleSECDialog}
        />
        {achScheduledEnabled ? (
          <TextInput
            label={l10n.getString("ach-payment-entry-desc")}
            maxLength={10}
            {...formAdapters.input(form, "entryDesc")}
          />
        ) : null}
      </ResponsiveFlex>
      <SECModal
        isOpen={showSECDialog}
        handleClose={toggleSECDialog}
        codes={codes}
      />
    </>
  );
};

const PaymentDetailsSection = ({
  limits,
}: {
  limits: LimitsForDisplayType;
}) => {
  const { l10n } = useLocalization();
  const { accounts } = useContext(AccountContext);
  const [showLimitsDialog, setShowLimitsDialog] = useState(false);
  const form = modules.achPayments.ACHPaymentForm.useForm();

  const validAccounts = accounts.filter((account: Account) =>
    account.isValidAchTransferSource(),
  );

  const toggleLimitsDialog = () => {
    setShowLimitsDialog(!showLimitsDialog);
  };

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {l10n.getString("ach-payment-payment-details-title")}
        </h4>
        <AccountSelector
          field="fromAccount"
          label="From"
          accounts={validAccounts}
          isDestination={true}
          showAddExternalAccountLink={false}
          {...formAdapters.select(form, "fromAccount")}
        />
        <TextInput
          label="Amount"
          {...formAdapters.amountInput(form, "amount")}
        />
        <Button
          label={l10n.getString("ach-payment-view-limits-cta")}
          kind="plain"
          size="s"
          onClick={toggleLimitsDialog}
        />
        {/* TODO: add cutoff logic */}
        <DateInput
          minDate={"today"}
          dateFormat={"m/d/Y"}
          altInput={true}
          altFormat={"m/d/Y"}
          field="date"
          label={l10n.getString("label-date-once")}
          useIsoOnChange={false}
          {...formAdapters.input(form, "startDate")}
        />
      </ResponsiveFlex>
      <LimitsAndDisclosuresDialog
        isOpen={showLimitsDialog}
        handleClose={toggleLimitsDialog}
        limits={limits}
      />
    </>
  );
};

const RecipientCardSection = () => {
  const { l10n } = useLocalization();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const selectedRecipient = entities.recipients.useSelectedRecipient();
  const hasManageRecipientPermission =
    modules.achPayments.useManageRecipientPermission();
  const accountNumber = selectedRecipient?.ach_destination?.account_number;
  const bankName = selectedRecipient?.ach_destination?.institution_name;
  const lastFour =
    accountNumber && accountNumber.substring(accountNumber.length - 4);
  const { nickname } = selectedRecipient as API.Recipient;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const PreviewCardActionButton = (
    <Button kind="plain" label="Edit" onClick={toggleDrawer} />
  );
  const PreviewCardIconContent = (
    <TruncatedAccount lastFour={lastFour} name={bankName} />
  );

  const getPreviewCardHeader = () => {
    let header = "";
    if (selectedRecipient) {
      header += selectedRecipient.name;
    }
    return header;
  };

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {l10n.getString("ach-payment-recipient-title")}
        </h4>
        <PreviewCard
          header={getPreviewCardHeader()}
          subtleHeader={nickname ? ` (${selectedRecipient?.nickname})` : ""}
          iconName="narmi-icon-bank"
          iconContent={PreviewCardIconContent}
          {...(hasManageRecipientPermission && {
            actionButton: PreviewCardActionButton,
          })}
        />
      </ResponsiveFlex>
      <RecipientDrawer
        isOpen={isDrawerOpen}
        handleClose={toggleDrawer}
        recipientId={selectedRecipient?.id}
        fromACHFlow={false}
        drawerType={DRAWER_TYPES.edit}
      />
    </>
  );
};

const RepeatPaymentCard = () => (
  // TODO: flush out more in follow up PR
  <ContentCard kind="bordered">
    <ResponsiveFlex gapSize="m">
      <FrequencySelector />
    </ResponsiveFlex>

    <ResponsiveFlex gapSize="m">
      <h4 className="fontSize--m nds-sans">Ends</h4>
      <RadioButtons
        name="options"
        onChange={function noRefCheck() {}}
        options={{
          OptionA: "Never",
          OptionB: "On",
          OptionC: "AFter",
        }}
      />
    </ResponsiveFlex>
  </ContentCard>
);
const PaymentScreen = ({ limits }: { limits: LimitsForDisplayType }) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as Features;
  const navigate = useNavigate();
  const { establishSudo } = useSudoContext();
  const codes = modules.achPayments.useSECCodes();
  const selectedRecipient = entities.recipients.useSelectedRecipient();
  const { onContinue } = modules.achPayments.usePaymentDetails();
  const [showRepeatPayment, setShowRepeatPayment] = useState(false);

  if (!selectedRecipient) return null;

  const handleClickNext = () => {
    const achScheduledEnabled = features.ach_payments_scheduled;
    const route = achScheduledEnabled
      ? ACH_PAYMENT_ADDITIONAL_DETAILS_ROUTE
      : ACH_PAYMENT_REVIEW_ROUTE;

    const handleNext = () => {
      navigate(`/${ACH_PAYMENT_ROUTE}/${route}`);
    };

    const handleTrySudo = () => {
      // if ach scheduled is enabled, don't try sudo on this step
      if (!achScheduledEnabled) {
        establishSudo(`/${ACH_PAYMENT_ROUTE}/${route}`);
      }
    };

    onContinue(handleNext, handleTrySudo);
  };

  const toggleRepeatPayment = () => {
    setShowRepeatPayment(!showRepeatPayment);
  };

  return (
    <>
      <ResponsiveFlex gapSize="xl">
        <HeaderWithSteps
          headerText={l10n.getString("ach-payment-payment-title")}
          step={2}
          totalSteps={getTotalSteps()}
          icon={
            <Tooltip text={l10n.getString("ach-payment-payment-title-tooltip")}>
              <i
                className="narmi-icon-info"
                style={{
                  fontSize: "var(--font-size-s)",
                }}
              />
            </Tooltip>
          }
        />

        <RecipientCardSection />

        <PaymentDetailsSection limits={limits} />

        {features.ach_payments_scheduled ? (
          <Row gapSize="s">
            <Toggle
              onChange={toggleRepeatPayment}
              labelledBy="repeat-payment-label"
            />
            <label id="repeat-payment-label" className="fontWeight--bold">
              {l10n.getString("ach-payment-repeat")}
            </label>
          </Row>
        ) : (
          <TransactionTypeSection codes={codes} />
        )}

        {showRepeatPayment ? <RepeatPaymentCard /> : null}

        <ProgressButtons
          backLabel={l10n.getString("button-back")}
          nextLabel={l10n.getString("button-next")}
          onBack={() => navigate(`/${ACH_PAYMENT_ROUTE}`)}
          onNext={handleClickNext}
        />
      </ResponsiveFlex>
    </>
  );
};

export default PaymentScreen;
